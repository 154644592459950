import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal'; 
import { faEdit, faComputer, faCar, faPlus, faChartGantt } from '@fortawesome/free-solid-svg-icons';
import './contactList.css';
import NumberSearch from './numberSearch';

Modal.setAppElement('#root');

const ContactList = () => {
  const { isLoggedIn } = useAuth();
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [materialFilter, setMaterialFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortOption, setSortOption] = useState('nextContact'); // Default to 'nextContact'
  const [userFilter, setUserFilter] = useState(''); 
  const [usersList, setUsersList] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [cityFilter, setCityFilter] = useState('');
  const location = useLocation();
  const [searchType, setSearchType] = useState('name');
  const userInfo = location.state?.userInfo || {};
  const storedTitle = localStorage.getItem('title');
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';
  const [localStorageSortOption, setLocalStorageSortOption] = useState(() => {
    const storedSortOption = localStorage.getItem('contactListSortOption');
    return storedSortOption || 'nextContact';
  });

  // New state to store user filter from localStorage
  const [localStorageUserFilter, setLocalStorageUserFilter] = useState(() => {
    const storedUserFilter = localStorage.getItem('contactListUserFilter');
    return storedUserFilter || '';
  });

  const handleClickAdmin = () => {
    // Show the modal
    setShowPerformanceModal(true);
  };

  const handleClickTeam = async () => {
    try {
      // Fetch team members
      const response = await axios.get(`${baseUrl}/api/current-user-team-members/${userInfo.id}`);
      setTeamMembers(response.data);
      
      // Show the modal
      setShowTeamModal(true);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };
  

  // Function to close the modal
  const handleCloseModal = () => {
    // Close the modal
    setShowPerformanceModal(false);
  };

  const handleCloseModalTeam = () => {
    // Close the modal
    setShowTeamModal(false);
  };

  // Update the stored user filter whenever the state changes
  useEffect(() => {
    localStorage.setItem('contactListUserFilter', localStorageUserFilter);
  }, [localStorageUserFilter]);

  // Set userFilter state based on localStorage when the component mounts
  useEffect(() => {
    setUserFilter(localStorageUserFilter);
  }, [localStorageUserFilter]);

  const handleUserFilterChange = (e) => {
    // Update both state and localStorage when the user filter changes
    setUserFilter(e.target.value);
    setLocalStorageUserFilter(e.target.value);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let contactsEndpoint;
// ahmed abdeen id 6643e495aa67f5f9d322f67f
        if (userInfo.id === '65ab064487d7d1637aeac596') {
          contactsEndpoint = `${baseUrl}/api/user-contacts-farouk?userIds=65ab064487d7d1637aeac596,66d4eae0268daec991d1b7a2,6620341fb8cf9369d48eb1d9`;
          const response = await axios.get(`${baseUrl}/api/current-user-team-members/${userInfo.id}`);
          setTeamMembers(response.data);
          // ibrahim
        } else if (userInfo.id === '6620341fb8cf9369d48eb1d9') {
          contactsEndpoint = `${baseUrl}/api/user-contacts-farouk?userIds=65ab064487d7d1637aeac596,66d4eae0268daec991d1b7a2,6620341fb8cf9369d48eb1d9`;
          const response = await axios.get(`${baseUrl}/api/current-user-team-members/${userInfo.id}`);
          setTeamMembers(response.data);
          // muhamed
        } else if (userInfo.id === '65abcd35a4e55cbcf2901773') {
          contactsEndpoint = `${baseUrl}/api/user-contacts-farouk?userIds=65abcd35a4e55cbcf2901773,664388f4aa67f5f9d31edf90,65ab064487d7d1637aeac596,6620341fb8cf9369d48eb1d9,6643e495aa67f5f9d322f67f,66d4eae0268daec991d1b7a2`;
          const response = await axios.get(`${baseUrl}/api/current-user-team-members/${userInfo.id}`);
          setTeamMembers(response.data);
          // karim
        } else if (userInfo.id === '664388f4aa67f5f9d31edf90') {
          contactsEndpoint = `${baseUrl}/api/user-contacts-farouk?userIds=65abcd35a4e55cbcf2901773,664388f4aa67f5f9d31edf90,65ab064487d7d1637aeac596,6620341fb8cf9369d48eb1d9,6643e495aa67f5f9d322f67f,66d4eae0268daec991d1b7a2`;
          const response = await axios.get(`${baseUrl}/api/current-user-team-members/${userInfo.id}`);
          setTeamMembers(response.data);
        } else if (userInfo.position === 'Sales') {
          contactsEndpoint = `${baseUrl}/api/user-contacts/${userInfo.id}`;
        } else {
          contactsEndpoint = `${baseUrl}/api/contacts`;
        }
        

        const response = await axios.get(contactsEndpoint);

        const sortedContacts = response.data.sort((a, b) => {
          const dateA = localStorageSortOption === 'nextContact' ? new Date(a.nextContact) : new Date(a.lastContact);
          const dateB = localStorageSortOption === 'nextContact' ? new Date(b.nextContact) : new Date(b.lastContact);

          return localStorageSortOption === 'nextContact' ? dateA - dateB : dateB - dateA;
        });

        setContacts(sortedContacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/usersall`);
        setUsersList(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchContacts();
    fetchUsers();

    // Save the sorting option to localStorage when it changes
    localStorage.setItem('contactListSortOption', localStorageSortOption);
  }, [baseUrl, userInfo.id, userInfo.position, localStorageSortOption, userFilter]);



  const resetFilters = () => {
    setSearchQuery('');
    setMaterialFilter('');
    setUserFilter('');
    setFilterStatus('All');
    setSortOption('nextContact');
    setLocalStorageUserFilter('');
    setLocalStorageSortOption('nextContact');
    setCityFilter('');
  };
  
  const isNextContactOlder = (nextContact) => {
    if (!nextContact) return false;

    const nextContactDate = new Date(nextContact);
    const presentDate = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
    nextContactDate.setHours(0, 0, 0, 0);
    presentDate.setHours(0, 0, 0, 0);

    return nextContactDate < presentDate;
  };

  const isNextContactDueToday = (nextContact) => {
    if (!nextContact) return false;

    const nextContactDate = new Date(nextContact);
    const presentDate = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
    nextContactDate.setHours(0, 0, 0, 0);
    presentDate.setHours(0, 0, 0, 0);

    return nextContactDate.getTime() === presentDate.getTime();
  };

  const handleSortByLastContact = () => {
    setLocalStorageSortOption('lastContact');
  };

  const handleSortByNextContact = () => {
    setLocalStorageSortOption('nextContact');
  };

  const renderMaterialIcon = (materialType) => {
    switch (materialType) {
      case 'Cats':
        return <FontAwesomeIcon className='materialIcon' icon={faCar} />;
      case 'Weee':
        return <FontAwesomeIcon className='materialIcon' icon={faComputer} />;
      case 'Both':
        return (
          <>
            <FontAwesomeIcon className='materialIcon' icon={faCar} />
            <FontAwesomeIcon className='materialIcon' icon={faComputer} />
          </>
        );
      default:
        return null;
    }
  };

// Filter contacts based on search query
const filteredContacts = contacts.filter(contact => {
  const lowerCaseName = contact.name.toLowerCase();
  const lowerCaseNumber = contact.number.toLowerCase();

  const includesName = lowerCaseName.includes(searchQuery.toLowerCase());
  const includesNumber = lowerCaseNumber.includes(searchQuery.toLowerCase());

  return (
    (searchType === 'name' && includesName) ||
    (searchType === 'number' && includesNumber)
  ) &&
  (materialFilter === '' || contact.materialType === materialFilter) &&
  (userFilter === '' || (contact.user && contact.user.id === userFilter)) &&
  (cityFilter === '' || contact.city === cityFilter) && 
  ((filterStatus === 'Overdue' && isNextContactOlder(contact.nextContact)) ||
    (filterStatus === 'Current' && isNextContactDueToday(contact.nextContact)) ||
    (filterStatus === 'Future' && !isNextContactOlder(contact.nextContact) && !isNextContactDueToday(contact.nextContact)) ||
    filterStatus === 'All');
});

  
  const handleEditClick = (contactId) => {
    // Navigate using the button concept instead of Link
    navigate(`/contact-details/${contactId}`, { state: { userInfo } });
  };

  const handleCalendarClick = () => {
    navigate('/calendar-view', { state: { userInfo } });
  };

  const handleScheduleAdminClick = () => {
    navigate('/schedule-admin-view', { state: { userInfo } });
  };

  const handleScheduleSalesClick = () => {
    navigate('/schedule-sales-view', { state: { userInfo } });
  };

  const handleCalendarPredictClick = () => {
    navigate('/calendar-predict-view', { state: { userInfo } });
  };


  const handleAddCustomerClick = () => {
    navigate('/contact-form', { state: { userInfo } });
  };

  const handleClickUserReport = () => {
    navigate('/user-report', { state: { userInfo } });

  };

  const handleClickUserReportTeam = (memberId) => {
    const member = usersList.find(user => user._id === memberId);
    if (member) {
    navigate('/user-report-team-member', { state: { userId: member._id } });
  } else {
    console.error('Selected user not found');
  }
};

  const handleClickAdminUserReport = (selectedUserId) => {
    const selectedUser = usersList.find(user => user._id === selectedUserId);
    if (selectedUser) {
      // If the selected user is found, navigate to the user report page with the userId
      navigate('/user-report-admin', { state: { userId: selectedUser._id } });
    } else {
      console.error('Selected user not found');
    }
  };
  
  // Create a list of unique cities
const uniqueCities = Array.from(new Set(contacts.map(contact => contact.city)));
  
    // Helper function to generate Google Maps link if the address contains coordinates
    const getGoogleMapsLink = (address) => {
      if (address.includes('°')) {
        const encodedAddress = encodeURIComponent(address);
        return `https://www.google.com/maps?q=${encodedAddress}`;
      }
      return null;
    };

      // Helper function to get the last note of a contact
  const getLastNote = (contact) => {
    if (contact.notes && contact.notes.length > 0) {
      const sortedNotes = contact.notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return sortedNotes[0].content;
    }
    return 'No notes available';
  };

  return (
    <div className='contactListMain'>
      {isLoggedIn && (
      <div className='contactListSecond'>
                <div className='filterButtons'>
            <button onClick={handleAddCustomerClick} className='contactButton'>Add Customer</button>
            {userInfo.position !== 'Sales' &&  userInfo.position !== 'Center' && (
          <button onClick={handleCalendarClick} className='contactButton'>Calendar</button>
        )}
          {userInfo.position !== 'Sales' && (
          <button onClick={handleScheduleAdminClick} className='contactButton'>Schedule</button>
        )}
                  {userInfo.position === 'Sales' && (
          <button onClick={handleScheduleSalesClick} className='contactButton'>Schedule</button>
        )}
          {userInfo.position !== 'Sales' && userInfo.position !== 'Center' && (
            <button onClick={handleCalendarPredictClick} className='contactButton'>
              Predict
            </button>
          )}
          {userInfo.position === 'Sales' &&  (
        <>
          <button onClick={handleClickUserReport} className='contactButton'>Performance</button>
          </>
      )}
                {userInfo.position === 'Center' && userInfo.name === 'Farouk' && (
        <>
          <button onClick={handleClickUserReport} className='contactButton'>Performance</button>
          </>
      )}
          {userInfo.position !== 'Sales' && userInfo.position !== 'Center' &&  (
        <>
          <button  className='contactButton' onClick={handleClickAdmin}>Admin</button>
          {showPerformanceModal && <Modal onClose={handleCloseModal} />}
        </>
      )}
          {(userInfo.name === 'Farouk' || userInfo.name === 'Mohamedrwiny') &&  (
        <>
          <button  className='contactButton' onClick={handleClickTeam}>Team</button>
          {showTeamModal && <Modal onClose={handleCloseModalTeam} />}
        </>
      )}
<Modal
  isOpen={showPerformanceModal}
  onRequestClose={() => setShowPerformanceModal(false)} // Corrected the function name
  contentLabel='Select User'
  style={{
    overlay: {
      top: '80px',
      width: '100%',
      background: 'black',
    },
    content: {
      margin: 'auto',
    },
  }}
>
  <div>
    <div  className='ulAssignUser'>
      <h2>Assign User</h2>
      <div className='modaluserlist'>
        {usersList.map((user) => (
          <div className='modaluserind' key={user._id}>
            {user.name} 
            <FontAwesomeIcon className='plusiconmodal' icon={faChartGantt} onClick={() => handleClickAdminUserReport(user._id)} /> {/* Pass user._id as argument */}
          </div>
        ))}
      </div>
      <button className='buttonModalAssignUser' onClick={() => setShowPerformanceModal(false)}>Close</button> {/* Corrected the function name */}
    </div>
  </div>
</Modal>

<Modal
  isOpen={showTeamModal}
  onRequestClose={() => setShowTeamModal(false)} 
  contentLabel='View Member'
  style={{
    overlay: {
      top: '80px',
      width: '100%',
      background: 'black',
    },
    content: {
      margin: 'auto',
    },
  }}
>
  <div>
    <div className='ulAssignUser'>
      <h2>View Member</h2>
      <div className='modaluserlist'>
        {teamMembers.map((member) => (
          <div className='modaluserind' key={member._id}>
            {member.name} 
            <FontAwesomeIcon className='plusiconmodal' icon={faChartGantt} onClick={() => handleClickUserReportTeam(member._id)} /> 
          </div>
        ))}
      </div>
      <button className='buttonModalAssignUser' onClick={() => setShowTeamModal(false)}>Close</button>
    </div>
  </div>
</Modal>





    </div>
      <h2>Contact List</h2>
      {userInfo.position === 'Sales' &&  (
                    <NumberSearch baseUrl={baseUrl} />
                  )}
        <div className='filterButtons'>
        <button className='contactButton' onClick={() => setMaterialFilter('Weee')}>Weee</button>
          <button className='contactButton' onClick={() => setMaterialFilter('Cats')}>Cats</button>
          <button className='contactButton' onClick={() => setMaterialFilter('Both')}>Both</button>
          <button className='contactButton' onClick={resetFilters}>Reset Filters</button>
        </div>
        <div className='filterButtons'>
           <button className='contactButton' onClick={() => setFilterStatus('Overdue')}>Overdue</button>
           <button className='contactButton' onClick={() => setFilterStatus('Current')}>Current</button>
           <button className='contactButton' onClick={() => setFilterStatus('Future')}>Future</button>
           <button className='contactButton' onClick={handleSortByLastContact}>Last</button>
          <button className='contactButton' onClick={handleSortByNextContact}>Next</button>
        </div>
        <div className='filterButtons'>
        <select onChange={(e) => setCityFilter(e.target.value)} value={cityFilter}>
          <option value="">All Cities</option>
          {uniqueCities.map((city, index) => (
            <option key={index} value={city}>{city}</option>
          ))}
  </select>
</div>

     {(userInfo.id === '65ab064487d7d1637aeac596' || userInfo.id === '65abcd35a4e55cbcf2901773' || userInfo.id === '6620341fb8cf9369d48eb1d9') &&  (
        <>
        <div className='filterButtons'>
        <select onChange={handleUserFilterChange} value={userFilter}>
        <option value="">All Users</option>
        {teamMembers.map((user) => (
          <option key={user._id} value={user._id}>{user.name}</option>
        ))}
      </select>
        </div>
        </>
      )}

{userInfo.position !== 'Sales' && userInfo.id !== '65ab064487d7d1637aeac596' && userInfo.id !== '6620341fb8cf9369d48eb1d9' && (
        <>
        <div className='filterButtons'>
        <select onChange={handleUserFilterChange} value={userFilter}>
        <option value="">All Users</option>
        {usersList.map((user) => (
          <option key={user._id} value={user._id}>{user.name}</option>
        ))}
      </select>
        </div>
        </>
      )}
        {userInfo.position !== 'Sales' &&  (      
             <>
        <div className='search-options'>
          <label>
            <input
              type="radio"
              name="searchType"
              value="name"
              checked={searchType === 'name'}
              onChange={() => setSearchType('name')}
            />
            Search by Name
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="number"
              checked={searchType === 'number'}
              onChange={() => setSearchType('number')}
            />
            Search by Number
          </label>
        </div>
                </>
              )}
        <input
          className='customerSearch'
          type="text"
          placeholder={searchType === 'name' ? 'Search by name' : 'Search by number'}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className='contactListGrid'>
          {filteredContacts.map((contact) => (
            <div
              key={contact._id}
              className={`contactRow 
                ${isNextContactOlder(contact.nextContact) ? 'redBackground' : ''}
                ${isNextContactDueToday(contact.nextContact) ? 'greenBackground' : ''}
                ${!isNextContactOlder(contact.nextContact) && !isNextContactDueToday(contact.nextContact) ? 'orangeBackground' : ''}
              `}
            >
              <div className='contactTopRow'>
              <div className='dataText'>
                <div className='dataTextSecond'>{contact.typeOfContact}:</div>{' '}
                {sortOption === 'nextContact'
                  ? (contact.nextContact ? new Date(contact.nextContact).toLocaleDateString('en-GB') : 'N/A')
                  : (contact.lastContact ? new Date(contact.lastContact).toLocaleDateString('en-GB') : 'N/A')}
              </div>
              <div className='dataText'>
              <div className='dataTextSecond'>{contact.name}</div>
              <div className='dataTextAddress'>
                  {getGoogleMapsLink(contact.address) ? (
                    <a href={getGoogleMapsLink(contact.address)} target="_blank" rel="noopener noreferrer">
                      {contact.address}
                    </a>
                  ) : (
                    contact.address
                  )}
                </div>
              <div className='dataTextCity'>{contact.city}</div>
              </div>
              <div className='dataText'>
              <div className='dataTextSecondUser'>{contact.user && contact.user.name}</div>
              </div>
              <div className='contactListIconBox'>
              <div className='materialIconsBox'>
              <div className='dataTextSecond'>{renderMaterialIcon(contact.materialType)}</div>
              </div>
              <div className='editIcon'>
                <button onClick={() => handleEditClick(contact._id)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
              </div>
              </div>
              <div className='contactBottomRow'>
              <p className="warehouse-contact-note">Last note: {getLastNote(contact)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default ContactList;
